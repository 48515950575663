<template>
  <div class="register">
     <h2>Database Alumni 588</h2>
     <form @submit.prevent="submitForm">
      <div class="row col-12">
        <div class="form-group col-md-6 col-sm-12">
           <CInput v-model="form.name" label="Nama Lengkap" placeholder="ketik disini" required/>
        </div>
        <div class="form-group col-md-6 col-sm-12">
           <CInput
             v-model="form.nickname"
             label="Nama Panggilan"
             placeholder="ketik disini"
             required
           />
        </div>
      </div>
      <div class="row col-12">
        <div class="form-group col-md-6 col-sm-12">
           <label class="form-label" for="gender">Jenis Kelamin</label>
           <select v-model="form.gender" class="form-control" required>
             <option value="1">Pria</option>
             <option value="2">Wanita</option>
           </select>
        </div>
        <div class="form-group col-md-6 col-sm-12">
           <label class="form-label" for="newData.image">Photo</label>
           <input
             type="file"
             class="form-control mb-3"
             id="newData.image"
             style="height: 45px;"
             @change="selectImage"
           />
        </div>
      </div>
      <div class="row col-12">
        <div class="form-group col-md-6 col-sm-12">
           <CInput
             v-model="form.birthday"
             label="Tanggal Ulang Tahun"
             placeholder="ketik disini"
             type="date"
             required
           />
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label class="form-label" for="newData.image">Status Pernikahan</label>
          <select v-model="form.marital_status" class="form-control mb-3">
            <option value="1">Belum kawin</option>
            <option value="2">Kawin</option>
            <option value="3">Cerai hidup</option>
            <option value="4">Cerai mati</option>
          </select>
        </div>
      </div>
      <div class="row col-12">
        <div class="form-group col-md-6 col-sm-12">
           <CInput
             v-model="form.number_of_children"
             label="Jumlah Anak"
             placeholder="ketik disini"
             required
           />
        </div>
        <div class="form-group col-md-6 col-sm-12">
           <CInput v-model="form.address" label="Alamat Rumah" placeholder="ketik disini" required/>
        </div>
      </div>
      <div class="row col-12">
        <div class="form-group col-md-6 col-sm-12">
           <CInput
             v-model="form.no_handphone"
             label="Nomor Handphone"
             placeholder="ketik disini"
             required
           />
        </div>
        <div class="form-group col-md-6 col-sm-12">
           <label for="Pekerjaan">Pekerjaan</label>
           <v-select
             class="mb-3"
             v-model="form.job"
             placeholder="Pilih Job"
             :options="computedJob"
             required
           ></v-select>
        </div>
      </div>
      <div class="row col-12">
        <div class="form-group col-md-6 col-sm-12">
          <CInput
          v-model="form.company"
          label="Nama Lembaga / Perusahaan"
          placeholder="ketik disini"
          required
          />
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <CInput
          v-model="form.position"
          label="Posisi / Jabatan"
          placeholder="ketik disini"
          required
          />
        </div>
      </div>
      <div class="row col-12">
        <div class="form-group col-md-6 col-sm-12">
           <CInput
             v-model="form.hobby"
             label="Hobby"
             placeholder="ketik disini"
             required
           />
        </div>
      </div>
       <button type="submit" @click="submit()">Submit</button>
     </form>
      <div>
        <audio ref="audioPlayer" :src="audioSource[currentSongIndex]" autoplay controls @ended="playNextSong" :style="{display:'none'}"></audio>
      </div>
      <div class="modal" v-if="showModal">
        <div class="modal-content">
          <p>Apakah ingin menyalakan musik?</p>
          <div style="max-width: 100%; align-content: center;">
            <button @click="startMusic()">Yes</button>
            <button @click="closeModal()">No</button>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

import { RouterLink } from 'vue-router'
import { uploadImage } from "@/utils/fileUpload"
import FileSaver from "file-saver"
import firebase from "firebase/compat/app"
import "firebase/auth"

export default {
  name: "Login",

  data() {
    return {
      selectedItems: [],
      file: null,
      jobs: [],
      total: 0,
      to: 0,
      items: [],
      form: {},
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
      },
      isSelectedAll: false,
      selectedAction: 0,
      audioSource: [
        './Tears For Fears  Everybody Wants To Rule The World Lyrics HD.mp3',
        './Duran Duran Save A Prayer lyrics.mp3',
        './Gold  Spandau Ballet Lyrics.mp3',
        './Guns N Roses  Dont Cry Lyrics.mp3',
      ],
      currentSongIndex: 0,
      showModal: false,
    };
  },
  
  methods: {
    
    selectImage(event) {
      console.log(event);
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.form.photo = resp;  
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    submit() {
      var loading = this.$loading.show();
      // console.log(this.form);
      // Check if a file is selected
      if (this.file) {
        // Upload the image file
        uploadImage(this.file)
          .then((imagePath) => {
            // Set the image path to the form data
            this.form.photo = imagePath;
            // Continue with the submission
            this.submitSurvey(loading);
          })
          .catch((e) => {
            loading.hide();
            this.$toast.error("Error uploading the image.");
          });
      } else {
        // No file selected, proceed with the submission without the image
        this.submitSurvey(loading);
      }
    },

    submitSurvey(loading) {
      // Insert data into the database
      this.form.job = this.form.job ? this.form.job.value : null
      this.$store
        .dispatch("auth/addSurvey", this.form)
        .then(() => {
          this.$toast.success("Berhasil menambahkan survey");
          loading.hide();
          this.createModal = false;
          this.form = {};
        })
        .catch((e) => {
          console.error(e);
          this.$toast.error(e);
          loading.hide();
        });
    },
    getJob() {
      this.$store
        .dispatch("auth/pekerjaan", this.params)
        .then((resp) => {
          this.jobs = resp.data.data.data
          this.total = resp.data.total
          this.to = resp.data.to

          // console.log(this.jobs)

          // khusus untuk checkbox
          this.selectedItems = []
          this.jobs.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element.id)
            } else {
              element.select = false
            }
          })
        })
        .catch((e) => {
          console.log(e)
        })
    },
    playNextSong() {
      // Increment the current song index
      this.currentSongIndex = (this.currentSongIndex + 1) % this.audioSource.length;

      // Update the audio source to the next song
      this.$refs.audioPlayer.src = this.audioSource[this.currentSongIndex];

      // Set the volume to 50% for the next song
      this.$refs.audioPlayer.volume = 0.2;

      // Play the next song
      this.$refs.audioPlayer.play();
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.$refs.audioPlayer.pause();
      this.$refs.audioPlayer.currentTime = 0;
      this.showModal = false;
    },
    startMusic() {
      // Add any logic to start or resume music playback
      this.$refs.audioPlayer.volume = 0.2;
      this.$refs.audioPlayer.play();
      this.showModal = false;
    },
  },
  computed: {
    computedJob() {
      return this.jobs.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
    },
  },
  mounted() {
    let image = require('@/assets/logo/bg.jpeg');
    document.body.style.backgroundImage = `url(${image})`;
    this.getJob()
    this.showModal = true;
  },
};
</script>

<style>
body {
  background-color: #000;
 background-repeat: center no-repeat;
 background-size: cover;
}

.register {
 width: 700px;
 margin: 0 auto;
 margin-top: 15px;
 margin-bottom: 15px;
 background: linear-gradient(45deg, #f8f9fa, #ffffff);
 /* background-color: rgba(255, 255, 255, 0.3); */
 padding: 20px;
 border-radius: 10px;
 box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.form-group {
 margin-bottom: 15px;
}

.form-group label {
 display: block;
 margin-bottom: 5px;
}

.form-group input,
.form-group select {
 width: 100%;
 padding: 5px;
 font-size: 14px;
 background-color: rgba(255, 255, 255, 0.8);
 border: 1px solid #51dcdf;
 border-radius: 5px;
}

button[type="submit"] {
 width: 100%;
 padding: 10px;
 background-color: #18a954;
 color: white;
 font-size: 16px;
 border: none;
 cursor: pointer;
}

button[type="submit"]:hover {
 background-color: #076332;
}

@media screen and (max-width: 480px) {
 .register {
    width: 100%;
 }

 .form-group input,
 .form-group select {
    font-size: 12px;
 }

 button[type="submit"] {
    padding: 7px;
    font-size: 14px;
 }
}

.modal {
  /* Modal styles */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  /* Modal content styles */
  max-width: 40%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.modal-content button {
  /* Button styles */
  background-color: #18a954; /* Change to your desired color */
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
}
</style>